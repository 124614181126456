/**
 * @generated SignedSource<<4ca10b52adc6a280c5e173930cf6b3bf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type OccurrenceStatus = "draft" | "published" | "%future added value";
export type ProductStatus = "archived" | "draft" | "published" | "unlisted" | "%future added value";
export type ProductType = "community_event" | "course" | "membership_plan" | "pathway" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type EditorEventAttachBlock_OccurrenceFragment$data = {
  readonly datetimeRange: ReadonlyArray<string>;
  readonly eventId: string;
  readonly hasViewerRSVPd: boolean;
  readonly id: string;
  readonly name: string | null;
  readonly product: {
    readonly name: string;
    readonly status: ProductStatus;
    readonly type: ProductType;
  };
  readonly status: OccurrenceStatus;
  readonly " $fragmentSpreads": FragmentRefs<"OccurrenceRSVPAttendanceStatusFragment" | "occurrenceUtils_useOccurrenceStatusFragment">;
  readonly " $fragmentType": "EditorEventAttachBlock_OccurrenceFragment";
};
export type EditorEventAttachBlock_OccurrenceFragment$key = {
  readonly " $data"?: EditorEventAttachBlock_OccurrenceFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"EditorEventAttachBlock_OccurrenceFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EditorEventAttachBlock_OccurrenceFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "eventId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasViewerRSVPd",
      "storageKey": null
    },
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Product",
      "kind": "LinkedField",
      "name": "product",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        },
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "datetimeRange",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OccurrenceRSVPAttendanceStatusFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "occurrenceUtils_useOccurrenceStatusFragment"
    }
  ],
  "type": "Occurrence",
  "abstractKey": null
};
})();

(node as any).hash = "dd9bdfd306f8c28a17ec9ed4ab0f3f88";

export default node;
