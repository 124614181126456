import { useGlobalDrawer } from "@/core/context/GlobalDrawerProvider"
import { useLabel } from "@/core/context/LabelsContext"
import CalendarIcon from "@/core/ui/iconsax/linear/calendar.svg"
import useUserTimezone from "@/user/util/useUserTimezone"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import DaySquare from "@components/date/day-square/DaySquare"
import { AttachBlockConfig } from "@components/editor/plugins/attach-block/AttachBlockNode"
import {
  MissingAccessAttachBlock,
  PlaceholderAttachBlock,
} from "@components/editor/plugins/attach-block/EditorAttachBlock"
import { EditorEventAttachBlockQuery } from "@components/editor/plugins/attach-block/types/event/__generated__/EditorEventAttachBlockQuery.graphql"
import { EditorEventAttachBlock_OccurrenceFragment$key } from "@components/editor/plugins/attach-block/types/event/__generated__/EditorEventAttachBlock_OccurrenceFragment.graphql"
import { DiscoChip, DiscoIcon, DiscoText, DiscoTooltip } from "@disco-ui"
import DiscoContainerButton from "@disco-ui/button/DiscoContainerButton"
import useActiveProductOrOrganizationPermissions from "@utils/hook/useActiveProductOrOrganizationPermissions"
import { DATE_FORMAT } from "@utils/time/timeConstants"
import { formatDateWithOptions } from "@utils/time/timeUtils"
import { graphql, useFragment, useLazyLoadQuery } from "react-relay"

const EditorEventAttachBlock: AttachBlockConfig = {
  title: "Event",
  icon: <CalendarIcon height={18} width={18} />,
  keywords: ["event", "link", "occurence", "meet"],

  Component({ entityId, onClose }) {
    const { occurrence } = useLazyLoadQuery<EditorEventAttachBlockQuery>(
      graphql`
        query EditorEventAttachBlockQuery($id: ID!) {
          occurrence: node(id: $id) {
            ... on Occurrence {
              id
              datetimeRange
              ...EditorEventAttachBlock_OccurrenceFragment
            }
          }
        }
      `,
      { id: entityId }
    )

    if (!occurrence || !occurrence?.datetimeRange?.[0]) {
      return <PlaceholderAttachBlock attachBlockEntity={"occurrence"} />
    }

    return <EditorEventAttachBlockContent occurrenceKey={occurrence} onClose={onClose} />
  },
}

interface Props {
  occurrenceKey: EditorEventAttachBlock_OccurrenceFragment$key
  onClose?: () => void
}

function EditorEventAttachBlockContent({ occurrenceKey, onClose }: Props) {
  const timezone = useUserTimezone()
  const permissions = useActiveProductOrOrganizationPermissions()
  const testid = "attach-block-editor.event"
  const membersLabel = useLabel("admin_member")
  const experienceLabel = useLabel("admin_experience")

  const eventDrawer = useGlobalDrawer("event")

  const occurrence = useFragment<EditorEventAttachBlock_OccurrenceFragment$key>(
    graphql`
      fragment EditorEventAttachBlock_OccurrenceFragment on Occurrence {
        id
        eventId
        hasViewerRSVPd
        name
        status
        product {
          status
          type
          name
        }
        datetimeRange
        ...OccurrenceRSVPAttendanceStatusFragment
        ...occurrenceUtils_useOccurrenceStatusFragment
      }
    `,
    occurrenceKey
  )

  const classes = useStyles()

  const isEventInDraftMode = occurrence.status === "draft"
  const isProductInDraftMode = occurrence.product?.status === "draft"

  if (
    (isEventInDraftMode || isProductInDraftMode) &&
    !permissions.has("content.manage")
  ) {
    return <MissingAccessAttachBlock attachBlockEntity={"occurrence"} />
  }

  const startsAt = new Date(occurrence.datetimeRange[0])

  return (
    <div className={classes.root}>
      <DiscoContainerButton
        onClick={(e) => {
          handleClick(e)
        }}
        testid={`${testid}.${occurrence.name}`}
        className={classes.attachBlockLink}
      >
        <div className={classes.infoWrapper}>
          <div className={classes.iconWrapper}>
            <DaySquare testid={`${testid}.DaySquare`} date={startsAt} />
          </div>
          <div className={classes.details}>
            <div className={classes.header}>
              <DiscoText
                truncateText={1}
                variant={"body-md-600"}
                testid={`${testid}.name`}
              >
                {occurrence.name}
              </DiscoText>
              {(isEventInDraftMode || isProductInDraftMode) && (
                <DiscoTooltip
                  content={
                    isEventInDraftMode
                      ? `This event is currently in draft mode and not visible to ${membersLabel.plural}`
                      : `This event is hidden to ${membersLabel.plural} because its ${experienceLabel.singular} is in draft mode`
                  }
                >
                  <div
                    data-testid={`${testid}.${occurrence.name}.draft-button${
                      isProductInDraftMode ? ".product-draft" : ""
                    }`}
                  >
                    {isProductInDraftMode && !isEventInDraftMode ? (
                      <DiscoChip
                        leftIcon={<DiscoIcon icon={"eye-off"} height={16} width={16} />}
                        color={"yellow"}
                        label={"Draft"}
                      />
                    ) : (
                      <DiscoChip color={"yellow"} label={"Draft"} />
                    )}
                  </div>
                </DiscoTooltip>
              )}
            </div>
            <DiscoText
              truncateText={1}
              variant={"body-xs"}
              color={"text.secondary"}
              testid={`${testid}.date`}
            >
              {formatDateWithOptions({
                timeZone: timezone,
                format:
                  DATE_FORMAT.REVERSE_DATE_WITH_WEEK_DAY_WITH_TIME_WITHOUT_YEAR_WITH_AT,
              })(startsAt)}
            </DiscoText>
            {occurrence.product?.type === "course" && (
              <DiscoText variant={"body-xs"} noWrap truncateText={1}>
                {`Hosted in `}
                <DiscoText
                  component={"span"}
                  variant={"body-xs-500"}
                  color={"primary.main"}
                >
                  {occurrence.product.name}
                </DiscoText>
              </DiscoText>
            )}
          </div>
        </div>
      </DiscoContainerButton>
    </div>
  )

  function handleClick(e: React.MouseEvent<Element, MouseEvent>) {
    if (!occurrence) return
    e.stopPropagation()
    if (onClose) onClose()
    eventDrawer.open({
      drawerOccurrenceId: occurrence.id,
      drawerEventTab: "details",
    })
  }
}

const useStyles = makeUseStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.measure.borderRadius.big,
    position: "relative",
    border: "1px solid",
    boxShadow: theme.palette.groovyDepths.xs,
    borderColor: theme.palette.divider,
    width: "400px",
    maxHeight: "80px",
  },
  attachBlockLink: {
    padding: 0,
    width: "100%",
    display: "flex",
    flexDirection: "row",
    height: "100%",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  infoWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: "0%",
    overflow: "hidden",
  },
  iconWrapper: {
    width: "48px",
    height: "48px",
    margin: theme.spacing(2, 0, 2, 2),
  },
  details: {
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(2),
    textAlign: "start",
    flex: "1 1 auto",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    maxWidth: "100%",
    textOverflow: "ellipsis",
  },
  header: {
    flex: "1 1 auto",
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
    justifyContent: "space-between",
  },
}))
export default EditorEventAttachBlock
