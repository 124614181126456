/**
 * @generated SignedSource<<2374b28e5801d88133aae8e51e0b8a63>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type ScormCompletionStatus = "completed" | "failed" | "incomplete" | "not_attempted" | "passed" | "%future added value";
export type StartScormFileInput = {
  contentUsageId: string;
  scormFileId: string;
};
export type EditorScormEmbed_startScormFileMutation$variables = {
  input: StartScormFileInput;
};
export type EditorScormEmbed_startScormFileMutation$data = {
  readonly response: {
    readonly errors: ReadonlyArray<{
      readonly field: string;
      readonly message: string;
    }> | null;
    readonly node: {
      readonly id: string;
      readonly scormData: string;
      readonly status: ScormCompletionStatus;
    } | null;
  };
};
export type EditorScormEmbed_startScormFileMutation = {
  response: EditorScormEmbed_startScormFileMutation$data;
  variables: EditorScormEmbed_startScormFileMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": "response",
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "StartScormFileResponse",
    "kind": "LinkedField",
    "name": "startScormFile",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ScormCompletion",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "scormData",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ValidationError",
        "kind": "LinkedField",
        "name": "errors",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "field",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EditorScormEmbed_startScormFileMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EditorScormEmbed_startScormFileMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "527202d232c27a5a6f3359baea21a048",
    "id": null,
    "metadata": {},
    "name": "EditorScormEmbed_startScormFileMutation",
    "operationKind": "mutation",
    "text": "mutation EditorScormEmbed_startScormFileMutation(\n  $input: StartScormFileInput!\n) {\n  response: startScormFile(input: $input) {\n    node {\n      id\n      scormData\n      status\n    }\n    errors {\n      field\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "49256704a484540973451d53a6cf4b96";

export default node;
