/**
 * @generated SignedSource<<f51f71bf72b79f30a976cb4f4feca602>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProductStatus = "archived" | "draft" | "published" | "unlisted" | "%future added value";
export type ProductType = "community_event" | "course" | "membership_plan" | "pathway" | "%future added value";
export type EditorProductAttachBlockQuery$variables = {
  id: string;
};
export type EditorProductAttachBlockQuery$data = {
  readonly product: {
    readonly badge?: {
      readonly " $fragmentSpreads": FragmentRefs<"BadgeFragment">;
    } | null;
    readonly cover?: string;
    readonly endsAt?: string | null;
    readonly id: string;
    readonly name?: string;
    readonly slug?: string;
    readonly startsAt?: string | null;
    readonly status?: ProductStatus;
    readonly type?: ProductType;
    readonly viewerMembership?: {
      readonly id: string;
    } | null;
    readonly " $fragmentSpreads": FragmentRefs<"usePermissionsFragment">;
  } | null;
};
export type EditorProductAttachBlockQuery = {
  response: EditorProductAttachBlockQuery$data;
  variables: EditorProductAttachBlockQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "ProductMembership",
  "kind": "LinkedField",
  "name": "viewerMembership",
  "plural": false,
  "selections": [
    (v2/*: any*/)
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endsAt",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startsAt",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cover",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "viewerPermissions",
  "storageKey": null
},
v12 = [
  (v11/*: any*/)
],
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "memberPermissions",
  "storageKey": null
},
v14 = [
  {
    "alias": "nonNullProductId",
    "args": null,
    "kind": "ScalarField",
    "name": "productId",
    "storageKey": null
  },
  (v11/*: any*/),
  (v13/*: any*/)
],
v15 = [
  {
    "alias": "nullableProductId",
    "args": null,
    "kind": "ScalarField",
    "name": "productId",
    "storageKey": null
  },
  (v11/*: any*/),
  (v13/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EditorProductAttachBlockQuery",
    "selections": [
      {
        "alias": "product",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Badge",
                "kind": "LinkedField",
                "name": "badge",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "BadgeFragment"
                  }
                ],
                "storageKey": null
              },
              (v10/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "usePermissionsFragment"
              }
            ],
            "type": "Product",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EditorProductAttachBlockQuery",
    "selections": [
      {
        "alias": "product",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Badge",
                "kind": "LinkedField",
                "name": "badge",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "kind",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "color",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "icon",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "emoji",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "mediaUrl",
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              (v10/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "kind": "InlineFragment",
                    "selections": (v12/*: any*/),
                    "type": "Organization",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": "nonNullProductId",
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                      },
                      (v11/*: any*/),
                      (v13/*: any*/)
                    ],
                    "type": "Product",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v14/*: any*/),
                    "type": "Event",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v15/*: any*/),
                    "type": "Comment",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v15/*: any*/),
                    "type": "Feed",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v15/*: any*/),
                    "type": "Post",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": "nullableProductIdString",
                        "args": null,
                        "kind": "ScalarField",
                        "name": "productId",
                        "storageKey": null
                      },
                      (v11/*: any*/),
                      (v13/*: any*/)
                    ],
                    "type": "ContentUsage",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v15/*: any*/),
                    "type": "ChatChannel",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v15/*: any*/),
                    "type": "Dashboard",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v14/*: any*/),
                    "type": "AssignmentSubmission",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v12/*: any*/),
                    "type": "PathwayGroup",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v12/*: any*/),
                    "type": "BrainSearch",
                    "abstractKey": null
                  }
                ],
                "type": "Node",
                "abstractKey": "__isNode"
              }
            ],
            "type": "Product",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4e67ad00c6569ac06a5ac7a8e6d20744",
    "id": null,
    "metadata": {},
    "name": "EditorProductAttachBlockQuery",
    "operationKind": "query",
    "text": "query EditorProductAttachBlockQuery(\n  $id: ID!\n) {\n  product: node(id: $id) {\n    __typename\n    id\n    ... on Product {\n      id\n      viewerMembership {\n        id\n      }\n      name\n      endsAt\n      startsAt\n      type\n      slug\n      status\n      badge {\n        ...BadgeFragment\n        id\n      }\n      cover\n      ...usePermissionsFragment\n    }\n  }\n}\n\nfragment BadgeFragment on Badge {\n  kind\n  color\n  icon\n  emoji\n  mediaUrl\n}\n\nfragment usePermissionsFragment on Node {\n  __isNode: __typename\n  __typename\n  ... on Organization {\n    viewerPermissions\n  }\n  ... on Product {\n    nonNullProductId: id\n    viewerPermissions\n    memberPermissions\n  }\n  ... on Event {\n    nonNullProductId: productId\n    viewerPermissions\n    memberPermissions\n  }\n  ... on Comment {\n    nullableProductId: productId\n    viewerPermissions\n    memberPermissions\n  }\n  ... on Feed {\n    nullableProductId: productId\n    viewerPermissions\n    memberPermissions\n  }\n  ... on Post {\n    nullableProductId: productId\n    viewerPermissions\n    memberPermissions\n  }\n  ... on ContentUsage {\n    nullableProductIdString: productId\n    viewerPermissions\n    memberPermissions\n  }\n  ... on ChatChannel {\n    nullableProductId: productId\n    viewerPermissions\n    memberPermissions\n  }\n  ... on Dashboard {\n    nullableProductId: productId\n    viewerPermissions\n    memberPermissions\n  }\n  ... on AssignmentSubmission {\n    nonNullProductId: productId\n    viewerPermissions\n    memberPermissions\n  }\n  ... on PathwayGroup {\n    viewerPermissions\n  }\n  ... on BrainSearch {\n    viewerPermissions\n  }\n}\n"
  }
};
})();

(node as any).hash = "413541cde4fbbca6d1288df71a0d0ad3";

export default node;
