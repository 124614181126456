import { useLabels } from "@/core/context/LabelsContext"
import GalleryIcon from "@/core/ui/iconsax/linear/gallery.svg"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import {
  ATTACH_BLOCK_CONFIG,
  AttachBlockEntity,
} from "@components/editor/plugins/attach-block/AttachBlockNode"
import { DiscoSection, DiscoText, DiscoTextSkeleton } from "@disco-ui"

import React from "react"

interface EditorAttachBlockProps {
  attachBlockEntity: AttachBlockEntity
  entityId: GlobalID
  onClose?: (event?: React.MouseEvent<HTMLButtonElement, MouseEvent> | undefined) => void
}

function EditorAttachBlock({
  attachBlockEntity,
  entityId,
  onClose,
}: EditorAttachBlockProps) {
  const config = ATTACH_BLOCK_CONFIG[attachBlockEntity]

  if (!config) return null
  const component = React.createElement(config.Component, { entityId, onClose })

  return <>{component}</>
}

export function PlaceholderAttachBlock({
  attachBlockEntity,
}: {
  attachBlockEntity: AttachBlockEntity
}) {
  const classes = useStyles()
  const attachBlockTitle = useAttachBlockTitle(attachBlockEntity)

  return (
    <div
      className={classes.root}
      data-testid={`attach-block-editor.${attachBlockTitle}.not-found`}
    >
      <DiscoSection className={classes.attachBlockLink}>
        <div className={classes.hiddenIconWrapper}>
          <GalleryIcon className={classes.icon} />
        </div>
        <div className={classes.details}>
          <DiscoText truncateText={1} variant={"body-md-600"}>
            {`Could not find attached ${attachBlockTitle}`}
          </DiscoText>
          <DiscoText truncateText={1} variant={"body-xs"}>
            {`The linked ${attachBlockTitle} may have been moved or deleted.`}
          </DiscoText>
        </div>
      </DiscoSection>
    </div>
  )
}
export function MissingAccessAttachBlock({
  attachBlockEntity,
}: {
  attachBlockEntity: AttachBlockEntity
}) {
  const classes = useStyles()
  const attachBlockTitle = useAttachBlockTitle(attachBlockEntity)
  return (
    <div
      className={classes.root}
      data-testid={`attach-block-editor.${attachBlockTitle}.no-access`}
    >
      <DiscoSection className={classes.attachBlockLink}>
        <div className={classes.hiddenIconWrapper}>
          <GalleryIcon className={classes.icon} />
        </div>
        <div className={classes.details}>
          <DiscoText truncateText={1} variant={"body-md-600"}>
            {`This ${attachBlockTitle} is not accessible`}
          </DiscoText>
          <DiscoText truncateText={1} variant={"body-xs"}>
            {`The linked ${attachBlockTitle} is hidden by admin.`}
          </DiscoText>
        </div>
      </DiscoSection>
    </div>
  )
}

function EditorAttachBlockSkeleton() {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <DiscoSection className={classes.attachBlockLink}>
        <div className={classes.hiddenIconWrapper} />
        <div className={classes.details}>
          <DiscoTextSkeleton variant={"body-lg-600"} width={"150px"} />
          <DiscoTextSkeleton variant={"body-sm-500"} width={"100px"} />
        </div>
      </DiscoSection>
    </div>
  )
}

function useAttachBlockTitle(attachBlockEntity: AttachBlockEntity) {
  const labels = useLabels()
  switch (attachBlockEntity) {
    case "contentUsage":
      return "content"
    case "occurrence":
      return "event"
    case "product":
      return labels.experience.singular.toLowerCase()
    case "pathway":
      return labels.pathway.singular.toLowerCase()
    case "survey":
      return "survey"
  }
}

const useStyles = makeUseStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
    borderColor: theme.palette.divider,
    borderRadius: theme.measure.borderRadius.big,
    position: "relative",
    boxShadow: theme.palette.groovyDepths.xs,
    width: "400px",
    height: "80px",
    border: "1px solid",
    padding: theme.spacing(1.5),
  },
  attachBlockLink: {
    padding: 0,
    width: "100%",
    display: "flex",
    flexDirection: "row",
    height: "100%",
    alignItems: "center",
    justifyContent: "left",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  hiddenIconWrapper: {
    backgroundColor: theme.palette.groovy.neutral[200],
    borderRadius: theme.measure.borderRadius.medium,
    width: "48px",
    height: "48px",
    marginRight: theme.spacing(1.5),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  details: {
    textAlign: "start",
    flex: "1 1 auto",
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(0.5),
  },
  icon: {
    height: "24px",
    width: "24px",
    color: theme.palette.groovy.neutral[400],
  },
}))

export default Relay.withSkeleton({
  component: EditorAttachBlock,
  skeleton: EditorAttachBlockSkeleton,
})
