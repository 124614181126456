/**
 * @generated SignedSource<<51cb6fb3bfb301570f7c99e03d1c3fbe>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DiscoEditorMentionsFragment$data = {
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"EditorMentionsProviderFragment">;
  readonly " $fragmentType": "DiscoEditorMentionsFragment";
};
export type DiscoEditorMentionsFragment$key = {
  readonly " $data"?: DiscoEditorMentionsFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"DiscoEditorMentionsFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DiscoEditorMentionsFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EditorMentionsProviderFragment"
    }
  ],
  "type": "Content",
  "abstractKey": null
};

(node as any).hash = "4f4a9facfe626be1bc5906db84e8211c";

export default node;
