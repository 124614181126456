import { ContentUsageUtils } from "@/content-usage/ContentUsageUtils"
import ContentThumbnail from "@/content/detail/ContentThumbnail"
import { useLabel } from "@/core/context/LabelsContext"
import { useUnsavedChangesModalContext } from "@/core/context/UnsavedChangesModalProvider"
import BookIcon from "@/core/ui/iconsax/linear/book-1.svg"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import CoverPhoto from "@components/cover-photo/CoverPhoto"
import { AttachBlockConfig } from "@components/editor/plugins/attach-block/AttachBlockNode"
import {
  MissingAccessAttachBlock,
  PlaceholderAttachBlock,
} from "@components/editor/plugins/attach-block/EditorAttachBlock"
import { EditorContentUsageAttachBlockQuery } from "@components/editor/plugins/attach-block/types/content-usage/__generated__/EditorContentUsageAttachBlockQuery.graphql"
import { DiscoChip, DiscoText, DiscoTooltip } from "@disco-ui"
import DiscoContainerButton from "@disco-ui/button/DiscoContainerButton"
import useActiveProductOrOrganizationPermissions from "@utils/hook/useActiveProductOrOrganizationPermissions"
import { setSearchParams } from "@utils/url/urlUtils"
import { useLazyLoadQuery } from "react-relay"
import { useHistory } from "react-router-dom"
import { graphql } from "relay-runtime"

const EditorContentUsageAttachBlock: AttachBlockConfig = {
  title: "Content",
  icon: <BookIcon height={18} width={18} />,
  keywords: ["link", "content", "lesson", "module"],
  Component({ entityId, onClose, attachBlockEntity = "contentUsage" }) {
    const classes = useStyles()
    const history = useHistory()
    const { handleLeave } = useUnsavedChangesModalContext()
    const testid = "attach-block-editor.content"

    const experienceLabel = useLabel("admin_experience")
    const membersLabel = useLabel("admin_member")

    const { contentUsage } = useLazyLoadQuery<EditorContentUsageAttachBlockQuery>(
      graphql`
        query EditorContentUsageAttachBlockQuery($id: ID!) {
          contentUsage: node(id: $id) {
            ... on ContentUsage {
              id
              entity
              isLocked
              content {
                id
                type
                label
                name
                type
                coverPhoto
                ...ContentThumbnail_ContentFragment
              }
              product {
                status
                name
                registrationAvailability
                slug
              }
              productApp {
                kind
                customAppTitle
              }
              module {
                name
              }
              ...ContentUsageUtils_useContentUsageLocationLabelContentUsageFragment
              ...ContentUsageUtils_useContentUsagePathContentUsageFragment
            }
          }
        }
      `,
      {
        id: entityId as GlobalID,
      }
    )

    const pathname = ContentUsageUtils.useContentUsagePath(contentUsage)
    const permissions = useActiveProductOrOrganizationPermissions()
    const isProductInDraftMode = contentUsage?.product?.status === "draft"

    if (!contentUsage?.content) {
      if (!permissions.has("content.manage"))
        return <MissingAccessAttachBlock attachBlockEntity={attachBlockEntity} />
      return <PlaceholderAttachBlock attachBlockEntity={attachBlockEntity} />
    }
    if (isProductInDraftMode && !permissions.has("content.manage")) {
      return <MissingAccessAttachBlock attachBlockEntity={attachBlockEntity} />
    }

    return (
      <div className={classes.root}>
        <DiscoContainerButton
          onClick={(e) => handleClick(e)}
          testid={`${testid}.${contentUsage.content.name}`}
          className={classes.attachBlockLink}
        >
          <div className={classes.infoWrapper}>
            <div className={classes.iconWrapper}>
              {contentUsage?.content.coverPhoto ? (
                <CoverPhoto coverPhoto={contentUsage?.content.coverPhoto} />
              ) : (
                <ContentThumbnail contentKey={contentUsage.content} />
              )}
            </div>
            <div className={classes.details}>
              <div className={classes.header}>
                <DiscoText
                  truncateText={1}
                  variant={"body-xs-600-uppercase"}
                  color={"primary.main"}
                >
                  {contentUsage.content.label}
                </DiscoText>
                {isProductInDraftMode && (
                  <DiscoTooltip
                    content={`This ${contentUsage.content.label} is hidden to ${membersLabel.plural} because its ${experienceLabel.singular} is in draft mode`}
                  >
                    <div
                      data-testid={`${testid}.${contentUsage.content.name}.draftButton`}
                      className={classes.chip}
                    >
                      <DiscoChip color={"yellow"} label={"Draft"} />
                    </div>
                  </DiscoTooltip>
                )}
              </div>
              <DiscoText truncateText={1} variant={"body-md-600"}>
                {contentUsage.content.name}
              </DiscoText>
            </div>
          </div>
        </DiscoContainerButton>
      </div>
    )

    function handleClick(e: React.MouseEvent<Element, MouseEvent>) {
      e.stopPropagation()
      if (onClose) onClose()
      handleLeave({
        onLeave: () => {
          if (!contentUsage?.id) return
          // If a folder or module, scroll the content usage into view
          if (
            contentUsage.entity === "collection" ||
            contentUsage.content?.type === "module"
          ) {
            history.push({
              ...location,
              pathname,
              hash: contentUsage.id,
            })
          }
          // Otherwise, just navigate to the content usage
          else {
            history.push({
              ...location,
              pathname,
              search: setSearchParams(location.search, {
                contentId: undefined,
                u: Relay.fromGlobalId(contentUsage.id).id,
              }),
            })
          }
        },
      })
    }
  },
}

const useStyles = makeUseStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.measure.borderRadius.big,
    position: "relative",
    border: "1px solid",
    boxShadow: theme.palette.groovyDepths.xs,
    borderColor: theme.palette.divider,
    maxHeight: "80px",
    width: "400px",
    height: "100%",
  },
  attachBlockLink: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    height: "100%",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  infoWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: "0%",
    overflow: "hidden",
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  iconWrapper: {
    display: "flex",
    padding: "auto",
    width: "96px",
    height: "48px",
    margin: theme.spacing(2, 0, 2, 2),
    "& svg path": {
      color: theme.palette.text.primary,
    },
    backgroundColor: theme.palette.groovy.neutral[100],
    borderRadius: "8px",
    alignItems: "center",
    justifyContent: "center",

    flexShrink: 0,
    flexGrow: 0,
    [theme.breakpoints.down("md")]: {
      width: "80px",
      height: "40px",
    },
  },

  details: {
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(2),
    textAlign: "start",
    flex: "1 1 auto",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    maxWidth: "100%",
    textOverflow: "ellipsis",
    gap: theme.spacing(0.5),
  },
  chip: {
    paddingLeft: theme.spacing(1),
  },
}))
export default EditorContentUsageAttachBlock
