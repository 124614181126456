/**
 * @generated SignedSource<<859ffa58d95bd23e0c98128917f04a05>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type ProductType = "community_event" | "course" | "membership_plan" | "pathway" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type AttachBlockEventModalListItem_OccurrenceFragment$data = {
  readonly __typename: "Occurrence";
  readonly datetimeRange: ReadonlyArray<string>;
  readonly id: string;
  readonly name: string | null;
  readonly product: {
    readonly name: string;
    readonly type: ProductType;
  };
  readonly " $fragmentType": "AttachBlockEventModalListItem_OccurrenceFragment";
};
export type AttachBlockEventModalListItem_OccurrenceFragment$key = {
  readonly " $data"?: AttachBlockEventModalListItem_OccurrenceFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"AttachBlockEventModalListItem_OccurrenceFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AttachBlockEventModalListItem_OccurrenceFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "datetimeRange",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Product",
      "kind": "LinkedField",
      "name": "product",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        },
        (v0/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "Occurrence",
  "abstractKey": null
};
})();

(node as any).hash = "8699cd772deb5ea0741c88d3bcce8268";

export default node;
