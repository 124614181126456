import Badge from "@/admin/experiences/badges/Badge"
import { useLabel } from "@/core/context/LabelsContext"
import { useUnsavedChangesModalContext } from "@/core/context/UnsavedChangesModalProvider"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import BoxIcon from "@/core/ui/iconsax/linear/box-1.svg"
import DefaultThumbnail from "@/core/ui/images/public-pages/default-thumbnail.png"
import { useProductLabel } from "@/product/util/hook/useProductLabel"
import ProductUtils from "@/product/util/productUtils"
import useUserTimezone from "@/user/util/useUserTimezone"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import CoverPhoto from "@components/cover-photo/CoverPhoto"
import { AttachBlockConfig } from "@components/editor/plugins/attach-block/AttachBlockNode"
import {
  MissingAccessAttachBlock,
  PlaceholderAttachBlock,
} from "@components/editor/plugins/attach-block/EditorAttachBlock"
import { EditorProductAttachBlockQuery } from "@components/editor/plugins/attach-block/types/product/__generated__/EditorProductAttachBlockQuery.graphql"
import { DiscoChip, DiscoText, DiscoTooltip } from "@disco-ui"
import DiscoContainerButton from "@disco-ui/button/DiscoContainerButton"
import usePermissions from "@utils/hook/usePermissions"
import { DATE_FORMAT } from "@utils/time/timeConstants"
import { formatDateWithOptions } from "@utils/time/timeUtils"
import classNames from "classnames"
import { graphql, useLazyLoadQuery } from "react-relay"
import { generatePath, useHistory } from "react-router-dom"

const EditorProductAttachBlock: AttachBlockConfig = {
  title: "Product",
  icon: <BoxIcon height={18} width={18} />,
  keywords: ["product", "experience", "link", "community"],

  Component({ entityId, onClose }) {
    const classes = useStyles()
    const { handleLeave } = useUnsavedChangesModalContext()
    const testid = "attach-block-editor.product"
    const membersLabel = useLabel("admin_member")
    const history = useHistory()
    const timeZone = useUserTimezone()

    const { product } = useLazyLoadQuery<EditorProductAttachBlockQuery>(
      graphql`
        query EditorProductAttachBlockQuery($id: ID!) {
          product: node(id: $id) {
            id
            ... on Product {
              id
              viewerMembership {
                id
              }
              name
              endsAt
              startsAt
              type
              slug
              status
              badge {
                ...BadgeFragment
              }
              cover
              ...usePermissionsFragment
            }
          }
        }
      `,
      { id: entityId }
    )

    const productLabel = useProductLabel(product?.type)

    const isProductInDraftMode = product?.status === "draft"
    const permissions = usePermissions(product)
    const canManageProduct = permissions.has("products.manage")

    if (isProductInDraftMode && !canManageProduct) {
      return <MissingAccessAttachBlock attachBlockEntity={"product"} />
    }

    if (!product) {
      return <PlaceholderAttachBlock attachBlockEntity={"product"} />
    }

    const userIsPartOfProduct = !!product.viewerMembership
    const canSkipRegistration = userIsPartOfProduct || canManageProduct

    const endDateText = formatDateWithOptions({
      timeZone,
      format: DATE_FORMAT.DEFAULT,
    })(new Date(product.endsAt!))

    function getCover() {
      if (!product) return
      switch (product.type) {
        case "course":
        case "pathway":
          return <Badge badgeKey={product.badge!} size={48} />
        default:
          return (
            <CoverPhoto
              coverPhoto={product.cover || DefaultThumbnail}
              customClassName={classes.cover}
            />
          )
      }
    }

    return (
      <div className={classes.root}>
        <DiscoContainerButton
          onClick={(e) => handleClick(e)}
          testid={`${testid}.${product.name}`}
          className={classes.attachBlockLink}
        >
          <div className={classes.infoWrapper}>
            <div
              className={classNames(classes.iconWrapper, {
                [classes.membershipPlanWrapper]: product.type === "membership_plan",
              })}
            >
              {getCover()}
            </div>
            <div className={classes.details}>
              <div className={classes.header}>
                <DiscoText
                  truncateText={1}
                  variant={"body-md-600"}
                  className={classes.headerTitle}
                >
                  {product.name}
                </DiscoText>
                <div>
                  {isProductInDraftMode && (
                    <DiscoTooltip
                      content={`This ${productLabel.singular} is currently in draft mode and not visible to ${membersLabel.plural}`}
                    >
                      <div data-testid={`${testid}.${product.name}.draftButton`}>
                        <DiscoChip color={"yellow"} label={"Draft"} />
                      </div>
                    </DiscoTooltip>
                  )}
                </div>
              </div>
              <DiscoText
                testid={`${testid}.${product.name}.text`}
                truncateText={1}
                variant={"body-xs"}
                color={"text.secondary"}
                padding={0}
              >
                {ProductUtils.displayDuration(product)
                  ? `${endDateText} · ${ProductUtils.displayDuration(product)}`
                  : "No fixed duration"}
              </DiscoText>
            </div>
          </div>
        </DiscoContainerButton>
      </div>
    )

    function handleClick(e: React.MouseEvent<Element, MouseEvent>) {
      e.stopPropagation()
      if (onClose) onClose()
      handleLeave({
        onLeave: () => {
          if (!product?.slug) return

          if (canSkipRegistration) {
            history.push(
              generatePath(ROUTE_NAMES.PRODUCT.DASHBOARD, {
                productSlug: product.slug,
              })
            )
          } else {
            history.push(
              generatePath(ROUTE_NAMES.PRODUCT.REGISTRATION.ROOT, {
                productSlug: product.slug,
              })
            )
          }
        },
      })
    }
  },
}

const useStyles = makeUseStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.measure.borderRadius.big,
    position: "relative",
    border: "1px solid",
    boxShadow: theme.palette.groovyDepths.xs,
    borderColor: theme.palette.divider,
    maxHeight: "80px",
    width: "400px",
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  headerTitle: {
    marginRight: theme.spacing(1),
  },
  attachBlockLink: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    height: "100%",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  infoWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: "0%",
    overflow: "hidden",
  },
  iconWrapper: {
    width: "48px",
    height: "48px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: theme.measure.borderRadius.big,
    backgroundColor: theme.palette.groovy.neutral[200],
    margin: theme.spacing(2, 0, 2, 2),
  },
  membershipPlanWrapper: {
    width: 100,
  },
  details: {
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(2),
    textAlign: "start",
    flex: "1 1 auto",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    maxWidth: "100%",
    textOverflow: "ellipsis",
    gap: theme.spacing(0.5),
  },
  cover: {
    borderRadius: theme.measure.borderRadius.default,
  },
}))
export default EditorProductAttachBlock
